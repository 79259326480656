<script>
import { layoutMethods, layoutComputed } from "@/state/helpers";
import { SimpleBar } from "simplebar-vue3";
import axios from 'axios';
import i18n from "../i18n";
import {AwardIcon} from "@zhuowenli/vue-feather-icons";
import Swal from "sweetalert2";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.svg"),
          language: "en",
          title: "English",
          value: 1,
        },
        {
          flag: require("@/assets/images/flags/spain.svg"),
          language: "sp",
          title: "Spanish",
          value: 2,
        },
      ],
      userPreferences: [],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      myVar: 1,
      userFullName: localStorage.getItem('firstName') +' '+ localStorage.getItem('lastname'),
      userFirstName: localStorage.getItem('firstName'),
      profile: localStorage.getItem('profile'),
      list: [],
      assist: [],
      userID: localStorage.getItem('id')
    };
  },
  components: { SimpleBar, AwardIcon },
  methods: {
    ...layoutMethods,
    isCustomDropdown() {
      //Search bar
      var searchOptions = document.getElementById("search-close-options");
      var dropdown = document.getElementById("search-dropdown");
      var searchInput = document.getElementById("search-options");

      searchInput.addEventListener("focus", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchInput.addEventListener("keyup", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchOptions.addEventListener("click", () => {
        searchInput.value = "";
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      });

      document.body.addEventListener("click", (e) => {
        if (e.target.getAttribute("id") !== "search-options") {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });
    },
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
        document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu")
          ? document.body.classList.remove("menu")
          : document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm"
            ? document.documentElement.setAttribute("data-sidebar-size", "")
            : document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg"
            ? document.documentElement.setAttribute("data-sidebar-size", "sm")
            : document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel")
          ? document.body.classList.remove("twocolumn-panel")
          : document.body.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      this.updateUserPreferences('language', this.lan)
      document.getElementById("header-lang-img").setAttribute("src", flag);
      i18n.global.locale = locale;
    },
    toggleDarkMode() {
      if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
        document.documentElement.setAttribute("data-layout-mode", "light");
        this.updateUserPreferences('nightMode', 'light')
        location.reload();
      } else {
        document.documentElement.setAttribute("data-layout-mode", "dark");
        this.updateUserPreferences('nightMode', 'dark')
        location.reload();
      }
    },
    async logout() {
       await axios
           .post('/api/logout')
           .then(response => {
             console.log(response)
           })
           .catch(error =>{
             console.log(JSON.stringify(error))
           })

      axios.defaults.headers.common['Authorization'] = ''
      localStorage.removeItem('jwt')
      this.$store.commit('removeToken')
    },
    async updateUserPreferences(preference, mode) {

      const data = {
        token: localStorage.getItem('jwt'),
        preference: preference,
        mode: mode
      }

      localStorage.setItem(preference, mode)
      await axios
          .post('/api/updatePreferences', data)
          .catch(error =>{
            console.log(JSON.stringify(error))
          })
    },
    async getPreferences(){
      const data = {
        token: localStorage.getItem('jwt'),
        userID: localStorage.getItem('id'),
      }

      axios
          .post('/api/getPreferences', data)
          .then(response => {
            let languageEng = this.languages[0]
            let languageSpa = this.languages[1]
            this.userPreferences = JSON.parse(response.data[0].json);

            // Language preferences
            if(languageEng.language === this.userPreferences.language) {
              this.setLanguage(this.userPreferences.language, languageEng.title, languageEng.flag)
            } else {
              this.setLanguage(this.userPreferences.language, languageSpa.title, languageSpa.flag)
            }

            localStorage.setItem('language', this.userPreferences.language)

            // Dark mode preferences
            if (this.userPreferences.nightMode === 'dark') {
              document.documentElement.setAttribute("data-layout-mode", "dark");
              document.documentElement.setAttribute("data-sidebar", "dark");
              document.documentElement.setAttribute("data-topbar", "dark");

              setTimeout(() => {
                document.documentElement.setAttribute("data-sidebar", "dark");
              }, 200);
            } else {
              document.documentElement.setAttribute("data-layout-mode", "light");
              document.documentElement.setAttribute("data-sidebar", "light");
              document.documentElement.setAttribute("data-topbar", "light");

              setTimeout(() => {
                document.documentElement.setAttribute("data-sidebar", "light");
                document.documentElement.setAttribute("data-topbar", "light");
              }, 100);
            }
            localStorage.setItem('nightMode', this.userPreferences.nightMode)
          })
          .catch(error => {
            if (error.response) {

              if(error.response.data.detail === 'Your session is expired!'){
                this.$router.push('logout')
              }

              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            }
          })
    },
    async getBootcampInfo(){

      const data = {
        token: localStorage.getItem('jwt'),
        isActiveState: 1,
        userID: localStorage.getItem('id')
      }

      axios
          .post('/api/getBootcamp', data)
          .then(response => {

            this.list= response.data;
            this.assist= JSON.parse(response.data.assistanceJson)
            this.listQty = this.list.length
            this.page = 1
            this.setPages();
          })
          .catch(error => {
            if (error.response) {

              if(error.response.data.detail === 'Your session is expired!'){
                this.$router.push('logout')
              }

              // for (const property in error.response.data) {
              // this.errors.push(`${property}: ${error.response.data[property]}`)
              // }
            } else if (error.message) {
              this.errors.push('Something was wrong, please contact your SysAdmin.')
            }
          })
    },
    async updateAssistance(userID=0, dayID=0, conditional=false){
      this.errors = []
      if (!this.errors.length) {
        const data = {
          token: localStorage.getItem('jwt'),
          userID: userID,
          dayID: dayID,
          conditional: conditional,
        }

        await axios
            .post('/api/updateAssistance', data)
            .then(async response => {
              if (response.status === 200) {
                await Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Assistance day '+ (parseInt(dayID)+1) +' updated!'
                });
              }
            })
            .catch(async error => {
              if (error.response) {

                if(error.response.data.detail === 'Your session is expired!'){
                  this.$router.push('logout')
                }

                let list = '';
                for (const property in error.response.data) {
                  this.errors.push(`${property}: ${error.response.data[property]}`)
                  list += '\n' + '<b>' + error.response.data[property] + '</b>';
                }

                await Swal.fire({
                  title: "Please check the following: " + list,
                  icon: 'warning',
                  confirmButtonClass: "btn btn-info",
                  buttonsStyling: false,
                  showCloseButton: true,
                });

              } else if (error.message) {
                this.errors.push('Something was wrong, please contact your SysAdmin.')
              }
            })
      } else {
        let list = '';
        for (let i = 0; i < this.errors.length; i++)
          list += '\n' + '<b>' + this.errors[i] + '</b>';

        await Swal.fire({
          title: "Please complete the form! " + list,
          icon: 'warning',
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    checkBootcampVisibility(){
      let value = localStorage.getItem('sID')
      if (value === '3' || value === '4'){
        return true
      }else{
        return false
      }

    }
  },
  mounted() {
    this.getBootcampInfo()

    if (document.getElementById("topnav-hamburger-icon"))
      document
          .getElementById("topnav-hamburger-icon")
          .addEventListener("click", this.toggleHamburgerMenu);

    this.isCustomDropdown();

    if(localStorage.getItem('language') === null || localStorage.getItem('nightMode') === null){
      this.getPreferences();
    }else{
      let languageEng = this.languages[0]
      let languageSpa = this.languages[1]
      let language = localStorage.getItem('language');

      // Language preferences
      if(languageEng.language === language) {
        this.setLanguage(language, languageEng.title, languageEng.flag)
      } else {
        this.setLanguage(language, languageSpa.title, languageSpa.flag)
      }

      let nightMode = localStorage.getItem('nightMode');
      // Dark mode preferences
      if (nightMode === 'dark') {
        document.documentElement.setAttribute("data-sidebar", "dark");
        document.documentElement.setAttribute("data-topbar", "dark");
        document.documentElement.setAttribute("data-layout-mode", "dark");
        setTimeout(() => {
          document.documentElement.setAttribute("data-sidebar", "dark");
        }, 200);
      } else {
        document.documentElement.setAttribute("data-topbar", "light");
        document.documentElement.setAttribute("data-layout-mode", "light");
        document.documentElement.setAttribute("data-sidebar", "light");

        setTimeout(() => {
          document.documentElement.setAttribute("data-sidebar", "light");
          document.documentElement.setAttribute("data-topbar", "light");
        }, 100);
      }
    }
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
      set(layout) {
        localStorage.setItem("rightbar_isopen", true);
        this.changeLayoutType({
          layoutType: layout,
        });
      },
    },
    layoutWidth: {
      get() {
        return this.$store ? this.$store.state.layout.layoutWidth : {} || {};
      },
      set(width) {
        this.changeLayoutWidth({
          layoutWidth: width,
        });
      },
    },
    topbar: {
      get() {
        return this.$store ? this.$store.state.layout.topbar : {} || {};
      },
      set(topbar) {
        this.changeTopbar({
          topbar: topbar,
        });
      },
    },
    sidebarSize: {
      get() {
        return this.$store ? this.$store.state.layout.sidebarSize : {} || {};
      },
      set(type) {
        return this.changeSidebarSize({
          sidebarSize: type,
        });
      },
    },
    position: {
      get() {
        return this.$store ? this.$store.state.layout.position : {} || {};
      },
      set(position) {
        return this.changePosition({
          position: position,
        });
      },
    },
    sidebarView: {
      get() {
        return this.$store ? this.$store.state.layout.sidebarView : {} || {};
      },
      set(sidebarView) {
        return this.changeSidebarView({
          sidebarView: sidebarView,
        });
      },
    },
    sidebarColor: {
      get() {
        return this.$store ? this.$store.state.layout.sidebarColor : {} || {};
      },
      set(sidebarColor) {
        return this.changeSidebarColor({
          sidebarColor: sidebarColor,
        });
      },
    },
    mode: {
      get() {
        return this.$store ? this.$store.state.layout.mode : {} || {};
      },
      set(mode) {
        return this.changeMode({
          mode: mode,
        });
      },
    },
  },
  watch:{}
};
</script>
<style>
@media print {
  html, body {
    display: none;  /* hide whole page */
  }
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  position: absolute;
  bottom: -1.6em;
  left: 100%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #f8f8f8),color-stop(1, #cccccc));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #cccccc);
  background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);
  background-image: -ms-linear-gradient(top, #f8f8f8, #cccccc);
  background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}
</style>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="17" />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-light.png" alt="" height="17" />
              </span>
            </router-link>
          </div>

          <button
            type="button"
            class="
              btn btn-sm
              px-3
              fs-16
              header-item
              vertical-menu-btn
              topnav-hamburger
            "
            id="topnav-hamburger-icon"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

          <!-- App Search-->
          <form class="app-search d-none d-md-block">
            <div class="position-relative">
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                autocomplete="off"
                id="search-options"
                value=""
              />
              <span class="mdi mdi-magnify search-widget-icon"></span>
              <span
                class="
                  mdi mdi-close-circle
                  search-widget-icon search-widget-icon-close
                  d-none
                "
                id="search-close-options"
              ></span>
            </div>
            <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
              <SimpleBar data-simplebar style="max-height: 320px">
                <!-- item-->
                <div class="dropdown-header">
                  <h6 class="text-overflow text-muted mb-0 text-uppercase">
                    Recent Searches
                  </h6>
                </div>

                <div class="dropdown-item bg-transparent text-wrap">
                  <router-link
                    to="/"
                    class="btn btn-soft-secondary btn-sm btn-rounded"
                    >how to setup <i class="mdi mdi-magnify ms-1"></i
                  ></router-link>
                  <router-link
                    to="/"
                    class="btn btn-soft-secondary btn-sm btn-rounded"
                    >buttons <i class="mdi mdi-magnify ms-1"></i
                  ></router-link>
                </div>
                <!-- item-->
                <div class="dropdown-header mt-2">
                  <h6 class="text-overflow text-muted mb-1 text-uppercase">
                    Pages
                  </h6>
                </div>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <i
                    class="
                      ri-bubble-chart-line
                      align-middle
                      fs-18
                      text-muted
                      me-2
                    "
                  ></i>
                  <span>Analytics Dashboard</span>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <i
                    class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"
                  ></i>
                  <span>Help Center</span>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <i
                    class="
                      ri-user-settings-line
                      align-middle
                      fs-18
                      text-muted
                      me-2
                    "
                  ></i>
                  <span>My account settings</span>
                </a>

                <!-- item-->
                <div class="dropdown-header mt-2">
                  <h6 class="text-overflow text-muted mb-2 text-uppercase">
                    Members
                  </h6>
                </div>

                <div class="notification-list">
                  <!-- item -->
                  <a
                    href="javascript:void(0);"
                    class="d-flex dropdown-item notify-item py-2"
                  >
                    <img
                      src="@/assets/images/users/avatar-2.jpg"
                      class="me-3 rounded-circle avatar-xs"
                      alt="user-pic"
                    />
                    <div class="flex-1">
                      <h6 class="m-0">Angela Bernier</h6>
                      <span class="fs-11 mb-0 text-muted">Manager</span>
                    </div>
                  </a>
                  <!-- item -->
                  <a
                    href="javascript:void(0);"
                    class="d-flex dropdown-item notify-item py-2"
                  >
                    <img
                      src="@/assets/images/users/avatar-3.jpg"
                      class="me-3 rounded-circle avatar-xs"
                      alt="user-pic"
                    />
                    <div class="flex-1">
                      <h6 class="m-0">David Grasso</h6>
                      <span class="fs-11 mb-0 text-muted">Web Designer</span>
                    </div>
                  </a>
                  <!-- item -->
                  <a
                    href="javascript:void(0);"
                    class="d-flex dropdown-item notify-item py-2"
                  >
                    <img
                      src="@/assets/images/users/avatar-5.jpg"
                      class="me-3 rounded-circle avatar-xs"
                      alt="user-pic"
                    />
                    <div class="flex-1">
                      <h6 class="m-0">Mike Bunch</h6>
                      <span class="fs-11 mb-0 text-muted">React Developer</span>
                    </div>
                  </a>
                </div>
              </SimpleBar>

              <div class="text-center pt-3 pb-1">
                <router-link
                  to="/pages/search-results"
                  class="btn btn-primary btn-sm"
                  >View All Results <i class="ri-arrow-right-line ms-1"></i
                ></router-link>
              </div>
            </div>
          </form>
        </div>

        <div class="d-flex align-items-center">
          <div class="dropdown d-md-none topbar-head-dropdown header-item">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="bx bx-search fs-22"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown"
            >
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search ..."
                      aria-label="Recipient's username"
                    />
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>


          <div class="dropdown ms-1 topbar-head-dropdown header-item" v-if="checkBootcampVisibility()">
            <button
                type="button"
                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              <AwardIcon></AwardIcon>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a class="dropdown-icon-item"
                 data-bs-toggle="modal"
                 href="#bootcampModal"
              >
                <span class="badge badge-pill bg-warning" data-key="t-new">Bootcamp Assistance</span>
              </a>
            </div>
          </div>

          <div class="dropdown ms-1 topbar-head-dropdown header-item">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                id="header-lang-img"
                src="@/assets/images/flags/us.svg"
                alt="Header Language"
                height="20"
                class="rounded"
              />
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- item--><a
                href="javascript:void(0);"
                v-for="(entry, i) in languages"
                :key="`Lang${i}`"
                :value="entry"
                @click="setLanguage(entry.language, entry.title, entry.flag)"
                :class="{ active: lan === entry.language}"
                class="dropdown-item notify-item language py-2"
                data-lang="en"
                title="English"
              >
                <img
                  :src="entry.flag"
                  alt="user-image"
                  class="me-2 rounded"
                  height="18"
                />
                <span class="align-middle">{{ entry.title }}</span>
              </a>
            </div>
          </div>

          <div class="dropdown ms-1 topbar-head-dropdown header-item">
            <button
                type="button"
                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              <img
                  id="header-lang-img"
                  src="@/assets/images/logo-dark.png"
                  alt="DMB"
                  data-title="eApp"
                  height="20"
                  class="rounded"
              />
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a class="dropdown-icon-item" href="https://university.dmbgroup.online/" target="_blank">
                <img
                    src="@/assets/images/logo-dark.png"
                    alt="Agent Portal"
                />
                <span>Agent Portal</span>
              </a>
              <a class="dropdown-icon-item" href="https://www.facebook.com/DMBAGENCY" target="_blank">
                <img
                    src="@/assets/images/facebook.png"
                    alt="Facebook"
                />
                <span>DMB Facebook</span>
              </a>
              <a class="dropdown-icon-item" href="https://www.instagram.com/dmbinsuranceagency" target="_blank">
                <img
                    src="@/assets/images/instagram.png"
                    alt="Lincoln Heritage"
                />
                <span>DMB Instagram</span>
              </a>
            </div>
          </div>

          <div class="dropdown ms-1 topbar-head-dropdown header-item">
            <button
                type="button"
                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              <img
                  id="header-lang-img"
                  src="@/assets/images/lhlicagents_logo.png"
                  alt="eApp Lincoln Heritage"
                  data-title="eApp"
                  height="20"
                  class="rounded"
              />
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a class="dropdown-icon-item" href="https://login.lhlicagents.com/login" target="_blank">
                <img
                    src="@/assets/images/lhlicagents_logo.png"
                    alt="eApp Lincoln Heritage"
                />
                <span>eApp</span>
              </a>
              <a class="dropdown-icon-item" href="https://www.lhlic.com/" target="_blank">
                <img
                    src="@/assets/images/lincoln_logo.jpg"
                    alt="Lincoln Heritage"
                />
                <span>Lincoln Heritage</span>
              </a>
            </div>
          </div>

          <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" data-toggle="fullscreen" @click="initFullScreen">
              <i class="bx bx-fullscreen fs-22"></i>
            </button>
          </div>

          <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class=" btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode" @click="toggleDarkMode">
              <i class="bx bx-moon fs-22"></i>
            </button>
          </div>

<!--          <div class="ms-1 header-item d-none d-sm-flex">-->
<!--            <button class=" btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"-->
<!--                    type="button"-->
<!--                    data-bs-toggle="offcanvas"-->
<!--                    data-bs-target="#phoneFunction"-->
<!--                    aria-controls="phoneFunction">-->
<!--              <i class="bx bx-phone fs-22"></i>-->
<!--            </button>-->
<!--          </div>-->

<!--         <div class="dropdown topbar-head-dropdown ms-1 header-item">-->
<!--            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--              <i class="bx bx-bell fs-22"></i>-->
<!--              <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">-->
<!--                0<span class="visually-hidden">unread messages</span></span>-->
<!--            </button>-->
<!--            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">-->
<!--              <div class="dropdown-head bg-primary bg-pattern rounded-top">-->
<!--                <div class="p-3">-->
<!--                  <div class="row align-items-center">-->
<!--                    <div class="col">-->
<!--                      <h6 class="m-0 fs-16 fw-semibold text-white">-->
<!--                        Notifications-->
<!--                      </h6>-->
<!--                    </div>-->
<!--                    <div class="col-auto dropdown-tabs">-->
<!--                      <span class="badge badge-soft-light fs-13"> 0 New</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--&lt;!&ndash;                <div class="px-2 pt-2">&ndash;&gt;-->
<!--&lt;!&ndash;                  <ul class="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true" id="notificationItemsTab" role="tablist">&ndash;&gt;-->
<!--&lt;!&ndash;                    <li class="nav-item">&ndash;&gt;-->
<!--&lt;!&ndash;                      <a class="nav-link active" data-bs-toggle="tab" href="#all-noti-tab" role="tab" aria-selected="false" @click.capture.stop>&ndash;&gt;-->
<!--&lt;!&ndash;                        All (4)&ndash;&gt;-->
<!--&lt;!&ndash;                      </a>&ndash;&gt;-->
<!--&lt;!&ndash;                    </li>&ndash;&gt;-->
<!--&lt;!&ndash;                    <li class="nav-item">&ndash;&gt;-->
<!--&lt;!&ndash;                      <a class="nav-link" data-bs-toggle="tab" href="#messages-tab" role="tab" aria-selected="true" @click.capture.stop>&ndash;&gt;-->
<!--&lt;!&ndash;                        Messages&ndash;&gt;-->
<!--&lt;!&ndash;                      </a>&ndash;&gt;-->
<!--&lt;!&ndash;                    </li>&ndash;&gt;-->
<!--&lt;!&ndash;                    <li class="nav-item">&ndash;&gt;-->
<!--&lt;!&ndash;                      <a class="nav-link" data-bs-toggle="tab" href="#alerts-tab" role="tab" aria-selected="false" @click.capture.stop>&ndash;&gt;-->
<!--&lt;!&ndash;                        Alerts&ndash;&gt;-->
<!--&lt;!&ndash;                      </a>&ndash;&gt;-->
<!--&lt;!&ndash;                    </li>&ndash;&gt;-->
<!--&lt;!&ndash;                  </ul>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--              </div>-->

<!--              <div class="tab-content" id="notificationItemsTabContent">-->

<!--&lt;!&ndash;                <div class="tab-pane fade py-2 ps-2 show active" id="all-noti-tab" role="tabpanel">&ndash;&gt;-->
<!--&lt;!&ndash;                  <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="text-reset notification-item d-block dropdown-item position-relative">&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="avatar-xs me-3">&ndash;&gt;-->
<!--&lt;!&ndash;                          <span class="avatar-title bg-soft-info text-info rounded-circle fs-16">&ndash;&gt;-->
<!--&lt;!&ndash;                            <i class="bx bx-badge-check"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                          </span>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="flex-1">&ndash;&gt;-->
<!--&lt;!&ndash;                          <a href="#!" class="stretched-link">&ndash;&gt;-->
<!--&lt;!&ndash;                            <h6 class="mt-0 mb-2 lh-base">&ndash;&gt;-->
<!--&lt;!&ndash;                              Your <b>Elite</b> author Graphic Optimization&ndash;&gt;-->
<!--&lt;!&ndash;                              <span class="text-secondary">reward</span> is&ndash;&gt;-->
<!--&lt;!&ndash;                              ready!&ndash;&gt;-->
<!--&lt;!&ndash;                            </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                          </a>&ndash;&gt;-->
<!--&lt;!&ndash;                          <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">&ndash;&gt;-->
<!--&lt;!&ndash;                            <span><i class="mdi mdi-clock-outline"></i> Just 30 sec ago</span>&ndash;&gt;-->
<!--&lt;!&ndash;                          </p>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="px-2 fs-15">&ndash;&gt;-->
<!--&lt;!&ndash;                          <input class="form-check-input" type="checkbox" />&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                    <div class="text-reset notification-item d-block dropdown-item position-relative">&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;                        <img src="@/assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="flex-1">&ndash;&gt;-->
<!--&lt;!&ndash;                          <a href="#!" class="stretched-link">&ndash;&gt;-->
<!--&lt;!&ndash;                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">&ndash;&gt;-->
<!--&lt;!&ndash;                              Angela Bernier&ndash;&gt;-->
<!--&lt;!&ndash;                            </h6></a>&ndash;&gt;-->
<!--&lt;!&ndash;                          <div class="fs-13 text-muted">&ndash;&gt;-->
<!--&lt;!&ndash;                            <p class="mb-1">&ndash;&gt;-->
<!--&lt;!&ndash;                              Answered to your comment on the cash flow forecast's graph 🔔.&ndash;&gt;-->
<!--&lt;!&ndash;                            </p>&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">&ndash;&gt;-->
<!--&lt;!&ndash;                            <span><i class="mdi mdi-clock-outline"></i> 48 min ago</span>&ndash;&gt;-->
<!--&lt;!&ndash;                          </p>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="px-2 fs-15">&ndash;&gt;-->
<!--&lt;!&ndash;                          <input class="form-check-input" type="checkbox" />&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                    <div class="text-reset notification-item d-block dropdown-item position-relative">&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="avatar-xs me-3">&ndash;&gt;-->
<!--&lt;!&ndash;                          <span&ndash;&gt;-->
<!--&lt;!&ndash;                            class="avatar-title bg-soft-danger text-danger rounded-circle fs-16"&ndash;&gt;-->
<!--&lt;!&ndash;                          >&ndash;&gt;-->
<!--&lt;!&ndash;                            <i class="bx bx-message-square-dots"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                          </span>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="flex-1">&ndash;&gt;-->
<!--&lt;!&ndash;                          <a href="#!" class="stretched-link">&ndash;&gt;-->
<!--&lt;!&ndash;                            <h6 class="mt-0 mb-2 fs-13 lh-base">&ndash;&gt;-->
<!--&lt;!&ndash;                              You have received <b class="text-success">20</b> new messages in the conversation&ndash;&gt;-->
<!--&lt;!&ndash;                            </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                          </a>&ndash;&gt;-->
<!--&lt;!&ndash;                          <p&ndash;&gt;-->
<!--&lt;!&ndash;                            class="mb-0 fs-11 fw-medium text-uppercase text-muted"&ndash;&gt;-->
<!--&lt;!&ndash;                          >&ndash;&gt;-->
<!--&lt;!&ndash;                            <span&ndash;&gt;-->
<!--&lt;!&ndash;                              ><i class="mdi mdi-clock-outline"></i> 2 hrs&ndash;&gt;-->
<!--&lt;!&ndash;                              ago</span&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                          </p>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="px-2 fs-15">&ndash;&gt;-->
<!--&lt;!&ndash;                          <input class="form-check-input" type="checkbox" />&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                    <div&ndash;&gt;-->
<!--&lt;!&ndash;                      class="text-reset notification-item d-block dropdown-item position-relative"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;                        <img&ndash;&gt;-->
<!--&lt;!&ndash;                          src="@/assets/images/users/avatar-8.jpg"&ndash;&gt;-->
<!--&lt;!&ndash;                          class="me-3 rounded-circle avatar-xs"&ndash;&gt;-->
<!--&lt;!&ndash;                          alt="user-pic"&ndash;&gt;-->
<!--&lt;!&ndash;                        />&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="flex-1">&ndash;&gt;-->
<!--&lt;!&ndash;                          <a href="#!" class="stretched-link"&ndash;&gt;-->
<!--&lt;!&ndash;                            ><h6 class="mt-0 mb-1 fs-13 fw-semibold">&ndash;&gt;-->
<!--&lt;!&ndash;                              Maureen Gibson&ndash;&gt;-->
<!--&lt;!&ndash;                            </h6></a&ndash;&gt;-->
<!--&lt;!&ndash;                          >&ndash;&gt;-->
<!--&lt;!&ndash;                          <div class="fs-13 text-muted">&ndash;&gt;-->
<!--&lt;!&ndash;                            <p class="mb-1">&ndash;&gt;-->
<!--&lt;!&ndash;                              We talked about a project on linkedin.&ndash;&gt;-->
<!--&lt;!&ndash;                            </p>&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <p&ndash;&gt;-->
<!--&lt;!&ndash;                            class="mb-0 fs-11 fw-medium text-uppercase text-muted"&ndash;&gt;-->
<!--&lt;!&ndash;                          >&ndash;&gt;-->
<!--&lt;!&ndash;                            <span&ndash;&gt;-->
<!--&lt;!&ndash;                              ><i class="mdi mdi-clock-outline"></i> 4 hrs&ndash;&gt;-->
<!--&lt;!&ndash;                              ago</span&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                          </p>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="px-2 fs-15">&ndash;&gt;-->
<!--&lt;!&ndash;                          <input class="form-check-input" type="checkbox" />&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                    <div class="my-3 text-center">&ndash;&gt;-->
<!--&lt;!&ndash;                      <button type="button" class="btn btn-soft-success">&ndash;&gt;-->
<!--&lt;!&ndash;                        View All Notifications&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="ri-arrow-right-line align-middle"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                      </button>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </SimpleBar>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                <div class="tab-pane fade py-2 ps-2" id="messages-tab" role="tabpanel" aria-labelledby="messages-tab">&ndash;&gt;-->
<!--&lt;!&ndash;                  <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="text-reset notification-item d-block dropdown-item">&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;                        <img src="@/assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="flex-1">&ndash;&gt;-->
<!--&lt;!&ndash;                          <a href="#!" class="stretched-link">&ndash;&gt;-->
<!--&lt;!&ndash;                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">&ndash;&gt;-->
<!--&lt;!&ndash;                              James Lemire&ndash;&gt;-->
<!--&lt;!&ndash;                            </h6></a>&ndash;&gt;-->
<!--&lt;!&ndash;                          <div class="fs-13 text-muted">&ndash;&gt;-->
<!--&lt;!&ndash;                            <p class="mb-1">&ndash;&gt;-->
<!--&lt;!&ndash;                              We talked about a project on linkedin.&ndash;&gt;-->
<!--&lt;!&ndash;                            </p>&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">&ndash;&gt;-->
<!--&lt;!&ndash;                            <span><i class="mdi mdi-clock-outline"></i> 30 min ago</span>&ndash;&gt;-->
<!--&lt;!&ndash;                          </p>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="px-2 fs-15">&ndash;&gt;-->
<!--&lt;!&ndash;                          <input class="form-check-input" type="checkbox" />&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                    <div class="text-reset notification-item d-block dropdown-item">&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;                        <img src="@/assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="flex-1">&ndash;&gt;-->
<!--&lt;!&ndash;                          <a href="#!" class="stretched-link">&ndash;&gt;-->
<!--&lt;!&ndash;                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">&ndash;&gt;-->
<!--&lt;!&ndash;                              Angela Bernier&ndash;&gt;-->
<!--&lt;!&ndash;                            </h6></a>&ndash;&gt;-->
<!--&lt;!&ndash;                          <div class="fs-13 text-muted">&ndash;&gt;-->
<!--&lt;!&ndash;                            <p class="mb-1">&ndash;&gt;-->
<!--&lt;!&ndash;                              Answered to your comment on the cash flow&ndash;&gt;-->
<!--&lt;!&ndash;                              forecast's graph 🔔.&ndash;&gt;-->
<!--&lt;!&ndash;                            </p>&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <p&ndash;&gt;-->
<!--&lt;!&ndash;                            class="mb-0 fs-11 fw-medium text-uppercase text-muted"&ndash;&gt;-->
<!--&lt;!&ndash;                          >&ndash;&gt;-->
<!--&lt;!&ndash;                            <span&ndash;&gt;-->
<!--&lt;!&ndash;                              ><i class="mdi mdi-clock-outline"></i> 2 hrs&ndash;&gt;-->
<!--&lt;!&ndash;                              ago</span&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                          </p>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="px-2 fs-15">&ndash;&gt;-->
<!--&lt;!&ndash;                          <input class="form-check-input" type="checkbox" />&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                    <div&ndash;&gt;-->
<!--&lt;!&ndash;                      class="text-reset notification-item d-block dropdown-item"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;                        <img&ndash;&gt;-->
<!--&lt;!&ndash;                          src="@/assets/images/users/avatar-6.jpg"&ndash;&gt;-->
<!--&lt;!&ndash;                          class="me-3 rounded-circle avatar-xs"&ndash;&gt;-->
<!--&lt;!&ndash;                          alt="user-pic"&ndash;&gt;-->
<!--&lt;!&ndash;                        />&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="flex-1">&ndash;&gt;-->
<!--&lt;!&ndash;                          <a href="#!" class="stretched-link"&ndash;&gt;-->
<!--&lt;!&ndash;                            ><h6 class="mt-0 mb-1 fs-13 fw-semibold">&ndash;&gt;-->
<!--&lt;!&ndash;                              Kenneth Brown&ndash;&gt;-->
<!--&lt;!&ndash;                            </h6></a&ndash;&gt;-->
<!--&lt;!&ndash;                          >&ndash;&gt;-->
<!--&lt;!&ndash;                          <div class="fs-13 text-muted">&ndash;&gt;-->
<!--&lt;!&ndash;                            <p class="mb-1">&ndash;&gt;-->
<!--&lt;!&ndash;                              Mentionned you in his comment on 📃 invoice&ndash;&gt;-->
<!--&lt;!&ndash;                              #12501.&ndash;&gt;-->
<!--&lt;!&ndash;                            </p>&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <p&ndash;&gt;-->
<!--&lt;!&ndash;                            class="mb-0 fs-11 fw-medium text-uppercase text-muted"&ndash;&gt;-->
<!--&lt;!&ndash;                          >&ndash;&gt;-->
<!--&lt;!&ndash;                            <span&ndash;&gt;-->
<!--&lt;!&ndash;                              ><i class="mdi mdi-clock-outline"></i> 10 hrs&ndash;&gt;-->
<!--&lt;!&ndash;                              ago</span&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                          </p>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="px-2 fs-15">&ndash;&gt;-->
<!--&lt;!&ndash;                          <input class="form-check-input" type="checkbox" />&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                    <div&ndash;&gt;-->
<!--&lt;!&ndash;                      class="text-reset notification-item d-block dropdown-item"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;                        <img&ndash;&gt;-->
<!--&lt;!&ndash;                          src="@/assets/images/users/avatar-8.jpg"&ndash;&gt;-->
<!--&lt;!&ndash;                          class="me-3 rounded-circle avatar-xs"&ndash;&gt;-->
<!--&lt;!&ndash;                          alt="user-pic"&ndash;&gt;-->
<!--&lt;!&ndash;                        />&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="flex-1">&ndash;&gt;-->
<!--&lt;!&ndash;                          <a href="#!" class="stretched-link"&ndash;&gt;-->
<!--&lt;!&ndash;                            ><h6 class="mt-0 mb-1 fs-13 fw-semibold">&ndash;&gt;-->
<!--&lt;!&ndash;                              Maureen Gibson&ndash;&gt;-->
<!--&lt;!&ndash;                            </h6></a&ndash;&gt;-->
<!--&lt;!&ndash;                          >&ndash;&gt;-->
<!--&lt;!&ndash;                          <div class="fs-13 text-muted">&ndash;&gt;-->
<!--&lt;!&ndash;                            <p class="mb-1">&ndash;&gt;-->
<!--&lt;!&ndash;                              We talked about a project on linkedin.&ndash;&gt;-->
<!--&lt;!&ndash;                            </p>&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <p&ndash;&gt;-->
<!--&lt;!&ndash;                            class="mb-0 fs-11 fw-medium text-uppercase text-muted"&ndash;&gt;-->
<!--&lt;!&ndash;                          >&ndash;&gt;-->
<!--&lt;!&ndash;                            <span&ndash;&gt;-->
<!--&lt;!&ndash;                              ><i class="mdi mdi-clock-outline"></i> 3 days&ndash;&gt;-->
<!--&lt;!&ndash;                              ago</span&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                          </p>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="px-2 fs-15">&ndash;&gt;-->
<!--&lt;!&ndash;                          <input class="form-check-input" type="checkbox" />&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                    <div class="my-3 text-center">&ndash;&gt;-->
<!--&lt;!&ndash;                      <button type="button" class="btn btn-soft-success">&ndash;&gt;-->
<!--&lt;!&ndash;                        View All Messages&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="ri-arrow-right-line align-middle"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                      </button>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </SimpleBar>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                <div class="tab-pane fade p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="w-25 w-sm-50 pt-3 mx-auto">&ndash;&gt;-->
<!--&lt;!&ndash;                    <img src="@/assets/images/svg/bell.svg" class="img-fluid" alt="user-pic"/>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="text-center pb-5 mt-2">&ndash;&gt;-->
<!--&lt;!&ndash;                    <h6 class="fs-18 fw-semibold lh-base">&ndash;&gt;-->
<!--&lt;!&ndash;                      Hey! You have no any notifications&ndash;&gt;-->
<!--&lt;!&ndash;                    </h6>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <div class="dropdown ms-sm-3 header-item topbar-user">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="d-flex align-items-center">
                <img class="rounded-circle header-profile-user " style="object-fit: cover;" v-bind:src="profile" />
                <span class="text-start ms-xl-2">
                  <span class=" d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{userFullName}}</span>
<!--                  <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Super Admin</span>-->
                </span>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <h6 class="dropdown-header">Welcome {{userFirstName}}!</h6>
              <router-link class="dropdown-item" to="/profile">
                <i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Profile</span>
              </router-link>
              <div class="dropdown-divider"></div>
              <router-link class="dropdown-item" to="/pages/profile-setting"
                ><span class="badge bg-soft-success text-success mt-1 float-end"
                  >New</span
                ><i
                  class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">Settings</span></router-link
              >
<!--              <router-link class="dropdown-item" to="/auth/lockscreen-basic"-->
<!--                ><i class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>-->
<!--                <span class="align-middle">Lock screen</span></router-link-->
<!--              >-->
              <a class="dropdown-item" href="/logout" @click="logout()">
                <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle" data-key="t-logout">Logout</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- right offcanvas -->
  <div class="offcanvas offcanvas-end" tabindex="-1" id="phoneFunction" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel">Offcanvas Right</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
    </div>
  </div>

  <!-- Bootcamp -->
  <BModal id="bootcampModal" hide-footer class="v-modal-custom" hide-header-close centered>
    <div class="modal-body text-center">

      <div colors="primary:#25a0e2,secondary:#00bd9d" trigger="loop"
           style="width: 120px; height: 120px; overflow: hidden; margin: 0px auto;">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500"
             width="500" height="500" preserveAspectRatio="xMidYMid meet"
             style="width: 100%; height: 100%; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
          <defs>
            <clipPath id="__lottie_element_136">
              <rect width="500" height="500" x="0" y="0"></rect>
            </clipPath>
            <g id="__lottie_element_146">
              <g transform="matrix(4.5,0,0,4.5,250.06300354003906,270.9770202636719)" opacity="1"
                 style="display: block;">
                <g opacity="1" transform="matrix(-1,0,0,1,0,0)">
                  <path fill="rgb(18,19,49)" fill-opacity="1"
                        d=" M-7.894623279571533,-0.7708492279052734 C-7.894623279571533,-0.7708492279052734 -22.89600944519043,-0.419234037399292 -22.89600944519043,-0.419234037399292 C-22.89600944519043,-0.419234037399292 -21.806299209594727,1.5694150924682617 -21.806299209594727,1.5694150924682617 C-21.806299209594727,1.5694150924682617 -8.55629825592041,4.694415092468262 -8.55629825592041,4.694415092468262 C-8.55629825592041,4.694415092468262 -7.894623279571533,-0.7708492279052734 -7.894623279571533,-0.7708492279052734z"></path>
                </g>
                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                  <path fill="rgb(18,19,49)" fill-opacity="1"
                        d=" M-7.894623279571533,-0.7708492279052734 C-7.894623279571533,-0.7708492279052734 -22.89600944519043,-0.419234037399292 -22.89600944519043,-0.419234037399292 C-22.89600944519043,-0.419234037399292 -21.806299209594727,1.5694150924682617 -21.806299209594727,1.5694150924682617 C-21.806299209594727,1.5694150924682617 -8.55629825592041,4.694415092468262 -8.55629825592041,4.694415092468262 C-8.55629825592041,4.694415092468262 -7.894623279571533,-0.7708492279052734 -7.894623279571533,-0.7708492279052734z"></path>
                </g>
              </g>
            </g>
            <filter id="__lottie_element_158" filterUnits="objectBoundingBox" x="0%" y="0%" width="100%"
                    height="100%">
              <feComponentTransfer in="SourceGraphic">
                <feFuncA type="table" tableValues="1.0 0.0"></feFuncA>
              </feComponentTransfer>
            </filter>
            <mask id="__lottie_element_146_2" mask-type="alpha">
              <g filter="url(#__lottie_element_158)">
                <rect width="500" height="500" x="0" y="0" fill="#ffffff" opacity="0"></rect>
                <use xlink:href="#__lottie_element_146"></use>
              </g>
            </mask>
          </defs>
          <g clip-path="url(#__lottie_element_136)">
            <g transform="matrix(4.5,0,0,4.5,250.75149536132812,251.125)" opacity="1" style="display: block;">
              <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(18,19,49)"
                      stroke-opacity="1" stroke-width="2.8000000000000003"
                      d=" M40.75,0 C40.75,22.690000534057617 22.35700035095215,41.08300018310547 -0.3330000042915344,41.08300018310547 C-23.023000717163086,41.08300018310547 -41.41699981689453,22.690000534057617 -41.41699981689453,0 C-41.41699981689453,-22.690000534057617 -23.023000717163086,-41.08300018310547 -0.3330000042915344,-41.08300018310547 C22.35700035095215,-41.08300018310547 40.75,-22.690000534057617 40.75,0z"></path>
              </g>
            </g>
            <g transform="matrix(4.500003814697266,0,0,4.500003814697266,250.7519989013672,169.48443603515625)"
               opacity="1" style="display: block;">
              <g opacity="1" transform="matrix(1,0,0,1,-0.125,21.625)">
                <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(18,19,49)"
                      stroke-opacity="1" stroke-width="2.8000000000000003"
                      d=" M12.267999649047852,16.886999130249023 C8.822999954223633,19.395000457763672 4.583000183105469,20.875 0,20.875 C-4.583000183105469,20.875 -8.822999954223633,19.395000457763672 -12.267999649047852,16.886999130249023"></path>
              </g>
            </g>
            <g mask="url(#__lottie_element_146_2)" style="display: block;">
              <g transform="matrix(4.5,0,0,4.5,250.75149536132812,267.3590087890625)" opacity="1">
                <g opacity="1" transform="matrix(-1,0,0,1,0,0)">
                  <g opacity="1" transform="matrix(1,0,0,1,-15.187999725341797,-9.812000274658203)">
                    <path fill="rgb(18,19,49)" fill-opacity="1"
                          d=" M0,-4 C2.2076001167297363,-4 4,-2.2076001167297363 4,0 C4,2.2076001167297363 2.2076001167297363,4 0,4 C-2.2076001167297363,4 -4,2.2076001167297363 -4,0 C-4,-2.2076001167297363 -2.2076001167297363,-4 0,-4z"></path>
                  </g>
                  <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(8,168,138)"
                        stroke-opacity="1" stroke-width="2.8000000000000003"
                        d=" M-11.25,-23.625 C-11.25,-23.625 -18.25,-24.625 -23.75,-18.875"></path>
                  <g opacity="1" transform="matrix(1,0,0,1,0,0)"></g>
                </g>
                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                  <g opacity="1" transform="matrix(1,0,0,1,-15.187999725341797,-9.812000274658203)">
                    <path fill="rgb(18,19,49)" fill-opacity="1"
                          d=" M0,-4 C2.2076001167297363,-4 4,-2.2076001167297363 4,0 C4,2.2076001167297363 2.2076001167297363,4 0,4 C-2.2076001167297363,4 -4,2.2076001167297363 -4,0 C-4,-2.2076001167297363 -2.2076001167297363,-4 0,-4z"></path>
                  </g>
                  <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" stroke="rgb(8,168,138)"
                        stroke-opacity="1" stroke-width="2.8000000000000003"
                        d=" M-11.25,-23.625 C-11.25,-23.625 -18.25,-24.625 -23.75,-18.875"></path>
                  <g opacity="1" transform="matrix(1,0,0,1,0,0)"></g>
                </g>
              </g>
            </g>
            <g class="com" style="display: none;">
              <g>
                <path></path>
              </g>
              <g>
                <path></path>
              </g>
              <g>
                <path></path>
              </g>
              <g>
                <path></path>
              </g>
              <g>
                <path></path>
              </g>
              <g>
                <path></path>
              </g>
              <g>
                <path></path>
              </g>
              <g>
                <path></path>
              </g>
              <g>
                <path></path>
              </g>
              <g>
                <path></path>
              </g>
              <g>
                <path></path>
              </g>
              <g>
                <path></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="mt-4" @click="getBootcampInfo"><h4 class="mb-3" >Keep your bootcamp Assistance Updated!</h4></div>

      <td v-for="(n, i) of this.assist" :key="i">
        <div class="form-check form-switch-md form-check-inline" dir="ltr">
            <span  v-for="(x, y) of n.assistance" :key="y">
<!--              <label class="form-check-label" :for="'inlineswitch'+parseInt(y)+1">&nbsp;{{ parseInt(y)+1}}&nbsp;</label>-->
              <div class="form-check form-switch">
                <label class="form-check-label" :for="'SwitchCheck1'+parseInt(y)+1"> Day &nbsp;{{ parseInt(y)+1}}&nbsp;</label>
                <input class="form-check-input" type="checkbox" role="switch" :id="parseInt(y)+1" v-if="x" checked @click="updateAssistance(userID, y, false)">
                <input class="form-check-input" type="checkbox" role="switch" :id="parseInt(y)+1" v-else  @click="updateAssistance(userID, y, true)">
              </div>
<!--              <input type="checkbox" class="form-check-input" :id="'inlineswitch'+parseInt(y)+1" v-if="x" checked @click="updateAssistance(userID, y, false)">-->
<!--              <input type="checkbox" class="form-check-input" :id="'inlineswitch'+parseInt(y)+1" v-else @click="updateAssistance(userID, y, true)">-->
            </span>
        </div>
      </td>

    </div>
  </BModal>
</template>
